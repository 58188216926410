import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { DatePicker, DateValidationError } from '@mui/x-date-pickers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { LoadingInputComponent } from 'components';
import { getkanalerByHenvendelsestypeId } from 'features/deltaker/state/deltakerSelectors';
import { fetchHenvendelseKanalIdsByHenvendelseType } from 'features/deltaker/state/henvendelse/kanaler/thunks';
import { postHenvendelse } from 'features/deltaker/state/henvendelse/sending/thunks';
import { resetOppfølgingspunkt } from 'features/deltaker/state/oppfølgingspunkt/slice';
import { useSysProperties } from 'features/sys';
import { getServertidspunkt } from 'features/system/systemAPI';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import {
  AdresseTypeKey,
  HenvendelseTypeDto,
  HenvendelseTypeKey,
} from 'services/henvendelser/dto';
import { isBlank } from 'utils';
import { PickerFormat, PickerProps, formatPickerToOutput } from 'utils/date';

import {
  AppendedFritekstbrevContainer,
  AppendedOppfølgingspunktContainer,
  DialogContentWrapper,
  DialogFormButtons,
} from '../components';
import { Henvendelsekategorier } from '../components/Henvendelsekategorier';
import { initialMinimalFormState } from '../fritekstBrev/utils';
import { henvendelseDialogSelector } from '../selectors';
import { initialFormState, initialFormStateInvalid } from '../utils';
import { HenvendelseDialogFormProps } from './interface';

const NyHenvendelseDialogForm: FC<HenvendelseDialogFormProps> = ({
  henvendelseTypeId,
  deltakerAdresser,
  effektivPostAdresseIdKryptert,
  close,
}) => {
  const dispatch = useAppDispatch();

  const sysHenvendelseTyper = useSysProperties('henvendelseTyper');
  const sysHenvendelseKanaler = useSysProperties('henvendelseKanaler');
  const { mapBeskrivelse } = useSysProperties('adresseTyper');

  const { isSubmitting, hasError } = useAppSelector(henvendelseDialogSelector);

  const [formState, setFormState] = useState({
    ...initialFormState,
    adresseIdKryptert: effektivPostAdresseIdKryptert,
  });
  const [formStateInvalid, setFormStateInvalid] = useState(
    initialFormStateInvalid
  );
  const [fritekstFormState, setFritekstFormState] = useState(
    initialMinimalFormState
  );
  const [confirmed, setConfirmed] = useState(false);
  const [servertidspunkLoaded, setServertidspunktLoaded] = useState(false);
  const [mottattTidspunkt, setMottattTidspunkt] = useState<PickerProps>({
    value: null,
    format: PickerFormat.DATE_TO_DATE_TIME,
  });
  const [showOppfølgingsform, setShowOppfølgingsform] = useState(false);
  const [showFritekstform, setShowFritekstform] = useState(false);

  useEffect(() => {
    getServertidspunkt()
      .then((response) =>
        setMottattTidspunkt((state) => {
          return { ...state, value: response };
        })
      )
      .catch(() => {})
      .finally(() => setServertidspunktLoaded(true));
  }, []);

  useEffect(() => {
    if (henvendelseTypeId) {
      dispatch(fetchHenvendelseKanalIdsByHenvendelseType(henvendelseTypeId));
    }
  }, [dispatch, henvendelseTypeId]);

  useEffect(() => {
    if (!showFritekstform) {
      setFritekstFormState(initialMinimalFormState);
    }
    if (!showOppfølgingsform) {
      dispatch(resetOppfølgingspunkt(null));
    }
  }, [dispatch, showFritekstform, showOppfølgingsform]);

  const closeHenvendelseDialog = () => {
    setFritekstFormState(initialMinimalFormState);
    dispatch(resetOppfølgingspunkt(null));
    close();
  };

  const handleShowOppfølgingsformChange = (show: boolean) => {
    if (!show) {
      dispatch(resetOppfølgingspunkt(null));
    }
    setShowOppfølgingsform(show);
  };

  const handleSetShowFritekstform = (show: boolean) => {
    if (!show) {
      setFritekstFormState(initialMinimalFormState);
    }
    setShowFritekstform(show);
  };

  const henvendelsesKanaler = useAppSelector((state) =>
    getkanalerByHenvendelsestypeId(state, henvendelseTypeId)
  );

  const isAllLoaded =
    sysHenvendelseTyper.isLoaded &&
    sysHenvendelseKanaler.isLoaded &&
    servertidspunkLoaded;

  if (!isAllLoaded) return null;

  const henvendelseType = sysHenvendelseTyper.map(
    henvendelseTypeId
  ) as HenvendelseTypeDto;

  const handleChange = (
    event:
      | React.ChangeEvent<{ name?: string; value: unknown }>
      | SelectChangeEvent<string | number | number[]>
  ) => {
    const { name, value } = event.target;
    if (name) {
      setFormState({ ...formState, [name]: value });
      setFormStateInvalid({ ...formStateInvalid, [name]: false });
    }
  };

  const handleInvalid = (event: React.FormEvent) => {
    event.preventDefault();

    const { name } = event.target as HTMLInputElement;
    setFormStateInvalid({ ...formStateInvalid, [name]: true });
  };

  const handleMottattTidspunktChange = (value: DateTime | null) => {
    setMottattTidspunkt({
      ...mottattTidspunkt,
      value: value,
    });
    setFormStateInvalid({ ...formStateInvalid, mottattTidspunkt: false });
  };

  const handleMottattTidspunktError = (error: DateValidationError) => {
    let message;
    switch (error) {
      case 'invalidDate':
        message = 'Mottatt dato må være på formatet dd.mm.åååå';
        break;
      case 'disableFuture':
        message = 'Mottatt dato kan ikke være i fremtiden';
        break;
      case null:
        break;
      default:
        message = 'Ugyldig dato';
    }
    setMottattTidspunkt({
      ...mottattTidspunkt,
      error: message,
      displayError: false,
    });
  };

  const displayMottattTidspunktError = () => {
    if (mottattTidspunkt.error) {
      setMottattTidspunkt({
        ...mottattTidspunkt,
        displayError: true,
      });
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const henvendelse = {
      ...formState,
      henvendelseKanalId: formState.henvendelseKanalId!,
      mottattTidspunkt: formatPickerToOutput(mottattTidspunkt),
      henvendelseTypeId: henvendelseTypeId!,
      personAdresseIdKryptert: formState.adresseIdKryptert,
    };

    const fritekstbrev = !isBlank(fritekstFormState.fritekst)
      ? {
          fritekst: fritekstFormState.fritekst,
          ...(!isBlank(fritekstFormState.hovedoverskrift) && {
            hovedoverskrift: fritekstFormState.hovedoverskrift,
          }),
        }
      : undefined;

    const personAdresse = !isBlank(formState.adresseIdKryptert)
      ? {
          personAdresseIdKryptert: formState.adresseIdKryptert,
        }
      : undefined;

    dispatch(
      postHenvendelse({
        henvendelse,
        fritekstbrev,
        personAdresse,
      })
    );
  };

  const handleConfirmCheckboxChange = () => {
    setConfirmed(!confirmed);
    setFormStateInvalid({
      ...formStateInvalid,
      confirmation: false,
    });
  };

  const handleFritekstbrevChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = event.target;

    if (name) {
      setFritekstFormState({ ...fritekstFormState, [name]: value });
    }
  };

  const errorMessage = hasError
    ? {
        title: 'Noe gikk galt ved lagring',
        message: 'Vennligst prøv igjen',
      }
    : undefined;

  function isPrøvesettAndMultipleDeltakerAdresse(henvendelseTypeId: number) {
    return (
      [
        HenvendelseTypeKey.NYTT_PRØVESETT,
        HenvendelseTypeKey.NYTT_PRØVESETT_ETTER_PRØVESVAR,
      ].includes(henvendelseTypeId) &&
      !!deltakerAdresser &&
      deltakerAdresser.length > 1
    );
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <DialogContentWrapper
        showDeltakerInfo
        tekstOverskrift={henvendelseType.tekstOverskrift}
        tekstVeiledning={henvendelseType.tekstVeiledning}
        hideDeltakerAdresse={isPrøvesettAndMultipleDeltakerAdresse(
          henvendelseType.id
        )}
        errorMessage={!showOppfølgingsform ? errorMessage : undefined}>
        {isPrøvesettAndMultipleDeltakerAdresse(henvendelseType.id) && (
          <>
            <FormLabel component="legend" sx={{ fontWeight: 'bold' }}>
              <b>Velg adresse</b>
            </FormLabel>
            <RadioGroup
              aria-labelledby="addresser-radio-group-label"
              name="adresseIdKryptert"
              sx={{ color: 'primary.main', paddingBottom: 2 }}
              value={
                formState.adresseIdKryptert || effektivPostAdresseIdKryptert
              }
              onChange={handleChange}>
              {deltakerAdresser?.map(({ idKryptert, adresse, adresseType }) => (
                <FormControlLabel
                  key={idKryptert}
                  value={idKryptert}
                  control={<Radio />}
                  disabled={
                    adresseType === AdresseTypeKey.KONTAKTADRESSE_I_UTLANDET
                      ? true
                      : false
                  }
                  label={adresse + ' [' + mapBeskrivelse(adresseType) + ']'}
                />
              ))}
            </RadioGroup>
          </>
        )}

        <LoadingInputComponent
          showLoading={!servertidspunkLoaded}
          marginBottom={2}>
          <DatePicker
            label="Dato mottatt"
            value={mottattTidspunkt.value}
            format={mottattTidspunkt.format.inputFormat}
            disableFuture
            onChange={handleMottattTidspunktChange}
            onError={handleMottattTidspunktError}
            slotProps={{
              textField: {
                id: 'mottattTidspunkt',
                name: 'mottattTidspunkt',
                required: true,
                error:
                  mottattTidspunkt.displayError ||
                  formStateInvalid.mottattTidspunkt,
                helperText: mottattTidspunkt.displayError
                  ? mottattTidspunkt.error
                  : '',
                onBlur: displayMottattTidspunktError,
                onInvalid: handleInvalid,
                sx: { mb: 2 },
              },
            }}
          />
        </LoadingInputComponent>
        <FormControl
          variant="filled"
          required
          fullWidth
          error={formStateInvalid.henvendelseKanalId}
          sx={{ mb: 2 }}>
          <LoadingInputComponent showLoading={!sysHenvendelseKanaler.isLoaded}>
            <InputLabel id="henvendelse-kanal-select">Velg kanal</InputLabel>
            <Select
              labelId="henvendelse-kanal-select"
              name="henvendelseKanalId"
              value={formState.henvendelseKanalId ?? ''}
              onInvalid={handleInvalid}
              onChange={handleChange}>
              {henvendelsesKanaler?.data?.map((henvendelsesKanalId: number) => (
                <MenuItem key={henvendelsesKanalId} value={henvendelsesKanalId}>
                  {sysHenvendelseKanaler.mapBeskrivelse(henvendelsesKanalId)}
                </MenuItem>
              ))}
            </Select>
          </LoadingInputComponent>
        </FormControl>
        <Henvendelsekategorier
          handleInvalid={handleInvalid}
          handleChange={handleChange}
          selectedValues={formState.henvendelseKategorier}
        />
        <TextField
          id="henvendelse-tekst"
          label="Vennligst utdyp nærmere..."
          name="tekst"
          fullWidth
          multiline
          spellCheck={false}
          rows={4}
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
        />
      </DialogContentWrapper>
      {henvendelseType.bekreftelsesbrevTilgjengelig && (
        <DialogContentWrapper>
          <FormControlLabel
            label="Send automatisk bekreftelsesbrev"
            control={
              <Checkbox
                checked={formState.bekreftelsesBrevØnsket}
                onChange={() =>
                  setFormState({
                    ...formState,
                    bekreftelsesBrevØnsket: !formState.bekreftelsesBrevØnsket,
                  })
                }
              />
            }
          />
        </DialogContentWrapper>
      )}
      {!!henvendelseType.tekstErDuSikker && (
        <DialogContentWrapper>
          <FormControlLabel
            label={henvendelseType.tekstErDuSikker}
            control={
              <Checkbox
                name="confirmation"
                required
                checked={confirmed}
                onChange={handleConfirmCheckboxChange}
                onInvalid={handleInvalid}
              />
            }
          />
        </DialogContentWrapper>
      )}

      {[
        HenvendelseTypeKey.NYTT_PRØVESETT,
        HenvendelseTypeKey.NYTT_PRØVESETT_ETTER_PRØVESVAR,
      ].includes(henvendelseType.id) && (
        <AppendedFritekstbrevContainer
          showContent={showFritekstform}
          setShowContent={handleSetShowFritekstform}
          handleChange={handleFritekstbrevChange}
          formState={fritekstFormState}
          disabled={false}
          appendedFritekstbrev={true}
        />
      )}

      <AppendedOppfølgingspunktContainer
        showContent={showOppfølgingsform}
        setShowContent={handleShowOppfølgingsformChange}
        errorMessage={showOppfølgingsform ? errorMessage : undefined}
      />

      <DialogFormButtons
        disabled={isSubmitting}
        isLoading={isSubmitting}
        close={closeHenvendelseDialog}
        tekstHandling={henvendelseType.tekstUtførKnapp}
      />
    </form>
  );
};

export default NyHenvendelseDialogForm;
